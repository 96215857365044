import {
  Button,
  Icon,
  SearchListTop,
  SearchOtherFilters,
  SearchPeriod,
  SearchStatus,
} from "../../index";
import { Form, Field, FormSpy } from "react-final-form";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { intlReplace } from "../../../utils/IntlReplace";
import { storeValues } from "../../../store/modules/VacanciesSearch";
import { debounce, isEqual } from "lodash";

import React, { Component } from "react";
import intl from "react-intl-universal";
import propTypes from "prop-types";

class Filter extends Component {
  constructor() {
    super();

    this.state = {
      values: {},
    };

    this.checkIsDiff = this.checkIsDiff.bind(this);
    this.submit = debounce(
      () => this.props.storeValues(this.state.values),
      700
    );
  }

  componentDidMount() {
    const { initialValues } = this.props;

    this.setState({
      values: initialValues,
    });
  }

  checkIsDiff(values) {
    const difference = isEqual(values, this.state.values);

    if (!difference) {
      this.setState(
        {
          values,
        },
        this.submit
      );
    }
  }

  render() {
    const {
      facets,
      activedFilters,
      toggleFilter,
      initialValues,
      featureFlags,
    } = this.props;

    return (
      <Form
        onSubmit={({ values }) => this.checkIsDiff(values)}
        initialValues={initialValues}
        render={() => (
          <div className="vg-search-form" role="search">
            <div className="vg-input-search">
              <Icon iconKey="search" aria-hidden />
              <Field
                name="q"
                component="input"
                type="text"
                placeholder={intl.get("VAGAS.BUSCA.INPUT")}
                data-testid="input-pesquisa-vaga"
                aria-label={intl.get("VAGAS.INPUT_ARIA_LABEL")}
              />
            </div>
            <Button
              onClick={() => toggleFilter("all-filters")}
              className="vg-open-filter"
              aria-label={`${intl.get(
                `VAGAS.BUSCA.${intlReplace(
                  featureFlags.includes("novo_status_vaga")
                    ? "status_atual"
                    : "status"
                )}.TITULO`
              )}`}
            >
              <Icon iconKey="filter" aria-hidden/>
            </Button>
            <div
              className={`vg-all-filter ${
                activedFilters.includes("all-filters") && "vg-filter-open"
              }`}
            >
              <SearchStatus
                content={
                  featureFlags.includes("novo_status_vaga")
                    ? facets.status_atual
                    : facets.status
                }
                type={
                  featureFlags.includes("novo_status_vaga")
                    ? "status_atual"
                    : "status"
                }
                i18n
              />
              <SearchPeriod type="data_de_criacao" />
              <SearchListTop
                content={facets.grupos}
                type="grupo"
                groupFilter
                ariaLabel={intl.get("VAGAS.BUSCA.ACCESSIBLE_NAME_GROUP")}
              />
              <SearchOtherFilters facets={facets} />
            </div>
            <FormSpy
              onChange={({ values }) => this.checkIsDiff(values)}
              subscription={{ values: true }}
            />
          </div>
        )}
      />
    );
  }
}

Filter.propTypes = {
  facets: propTypes.object.isRequired,
  initialValues: propTypes.object.isRequired,
  storeValues: propTypes.func.isRequired,
  toggleFilter: propTypes.func.isRequired,
  activedFilters: propTypes.array.isRequired,
  values: propTypes.any,
  passthroughValues: propTypes.object,
  featureFlags: propTypes.array,
};

export default connect(undefined, (dispatch) =>
  bindActionCreators({ storeValues }, dispatch)
)(Filter);
