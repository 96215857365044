import propTypes from "prop-types";
import React, { Component } from "react";
import { map } from "lodash";
import shortid from "shortid";
import intl from "react-intl-universal";
import "./Radio.scss";

class RadioButton extends Component {
  render() {
    const { inputs, intialChecked, handleRadioCheck, id } = this.props;

    return (
      <div className="vg-custom-radio">
        {map(inputs, ([name, value, text]) => {
          return (
            <label
              className="vg-label"
              key={shortid.generate()}
            >
              <input
                type="radio"
                defaultChecked={intialChecked === value}
                value={value}
                onChange={(e) => handleRadioCheck(e)}
                name={`${name}-${id}`}
                id={shortid.generate()}
              />
              <span className="vg-label-text">{intl.get(text)}</span>
            </label>
          );
        })}
      </div>
    );
  }
}

RadioButton.defaultProps = {
  name: "",
  inputs: [],
};

RadioButton.propTypes = {
  intialChecked: propTypes.any.isRequired,
  name: propTypes.string.isRequired,
  inputs: propTypes.array.isRequired,
  id: propTypes.any.isRequired,
  handleRadioCheck: propTypes.func.isRequired,
};

export default RadioButton;
