import "./VacanciesSearch.scss";

import { Field } from "react-final-form";
import React, { Component } from "react";
import { includes, map } from "lodash";

import { Icon } from "../../index";
import Label from "./Label";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import onClickOutside from "react-onclickoutside";
import propTypes from "prop-types";
import { toggleFilter } from "../../../store/modules/VacanciesSearch";

const mapStateToProps = (state, props) => ({
  totalSelected: state.VacanciesSearch.filter[props.type]
    ? Object.values(state.VacanciesSearch.filter[props.type]).filter((it) => it)
        .length
    : 0,
  activedFilters: state.VacanciesSearch.activedFilters,
});

class SearchStatus extends Component {
  constructor(props) {
    super(props);
    this.content = props.content;

    this.state = {
      filterValue: "",
      items: this.content,
    };

    this.filter = this.filter.bind(this);
  }

  prepareString(value) {
    return value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  filter(value) {
    const filtered = this.content.filter((obj) =>
      includes(this.prepareString(obj.desc), this.prepareString(value))
    );

    this.setState({
      items: filtered,
    });
  }

  handleClickOutside() {
    const { toggleFilter, type, activedFilters } = this.props;
    activedFilters.includes(type) && toggleFilter(type);
  }

  render() {
    const {
      type,
      i18n,
      insideLabel,
      totalSelected,
      inside,
      activedFilters,
      toggleFilter,
    } = this.props;
    const { items } = this.state;

    return (
      <div
        className={`vg-filter ${
          activedFilters.includes(type) && "vg-filter-open"
        } ${inside && "vg-filter-more"}`}
      >
        <button
          className="vg-filter-title"
          onClick={() => toggleFilter(type)}
          type="button"
          aria-expanded={activedFilters.includes(type)}
          aria-controls={`${type}-menu`}
        >
          {intl.get(`VAGAS.BUSCA.${intlReplace(type)}.TITULO`)}:
          <span className="vg-filter-smart-placeholder">
            {intl.getHTML("VAGAS.BUSCA.ITEM", { value: totalSelected })}
          </span>
          <Icon iconKey="arrow" aria-hidden />
        </button>
        <div className="vg-menu-filter">
          {insideLabel && (
            <label className="vg-label-title">{insideLabel}</label>
          )}
          <div className="vg-filter-scroll" id={`${type}-menu`}>
            <fieldset className="vg-fieldset">
              <legend>
                {map(items, (item) => (
                  <div className="vg-custom-check">
                    <label className="vg-label">
                      <span className="vg-input-checkbox">
                        <Field
                          name={type}
                          component="input"
                          type="checkbox"
                          value={item.key}
                        />
                        <span className="vg-input-checkbox-inner" />
                      </span>
                      <Label i18n={i18n} type={type} item={item} />
                    </label>
                  </div>
                ))}
              </legend>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

SearchStatus.defaultProps = {
  totalSelected: 0,
};

SearchStatus.propTypes = {
  clickOutside: propTypes.func,
  toggleFilter: propTypes.func.isRequired,
  content: propTypes.array,
  activedFilters: propTypes.array.isRequired,
  type: propTypes.string.isRequired,
  i18n: propTypes.bool,
  inside: propTypes.bool,
  insideLabel: propTypes.string,
  totalSelected: propTypes.number.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ toggleFilter }, dispatch)
)(onClickOutside(SearchStatus));
