import { Field } from "react-final-form";
import React, { Component } from "react";
import { includes, map } from "lodash";
import { Icon } from "../../index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import onClickOutside from "react-onclickoutside";
import propTypes from "prop-types";
import {
  toggleFilter,
  uncheckAll,
  uncheck,
} from "../../../store/modules/VacanciesSearch";
import InputFilter from "./InputFilter";
import DynamicLabel from "./DynamicLabel";
import Label from "./Label";

const mapStateToProps = (state, props) => ({
  totalSelected: state.VacanciesSearch.filter[props.type]
    ? Object.values(state.VacanciesSearch.filter[props.type]).filter((it) => it)
        .length
    : 0,
  activedFilters: state.VacanciesSearch.activedFilters,
  filters: state.VacanciesSearch.filter[props.type],
});

class SearchListTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      totalList: 5,
      filtered: [],
      filtering: false,
    };

    this.filter = this.filter.bind(this);
    this.loading = this.loading.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.content.length !== state.items.length) {
      return {
        items: props.content,
      };
    }

    return null;
  }

  prepareString(value = "") {
    return value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  filter(value) {
    if (value) {
      const { content } = this.props;

      const filtered = content.filter((obj) =>
        includes(this.prepareString(obj.desc), this.prepareString(value))
      );

      this.setState({
        filtered: filtered,
        noResults: !filtered.length,
        filtering: true,
      });
    } else {
      this.setState({
        filtering: false,
        noResults: false,
      });
    }
  }

  loading(bool) {
    this.setState({
      loading: bool,
    });
  }

  handleClickOutside() {
    const { toggleFilter, type, activedFilters } = this.props;
    activedFilters.includes(type) && toggleFilter(type);
  }

  render() {
    const {
      type,
      totalSelected,
      inside,
      activedFilters,
      toggleFilter,
      content,
      filters,
      uncheckAll,
      uncheck,
      groupFilter = false,
      ariaLabel,
    } = this.props;
    const { items, totalList, loading, filtered, noResults, filtering } =
      this.state;

    return (
      <div
        className={`vg-filter ${
          activedFilters.includes(type) && "vg-filter-open"
        } ${inside && "vg-filter-more"}`}
      >
        {groupFilter ? (
          <button
            className="vg-filter-title"
            onClick={() => toggleFilter(type)}
            type="button"
            aria-expanded={activedFilters.includes(type)}
            aria-controls={`${type}-menu`}
          >
            {intl.get(`VAGAS.BUSCA.${intlReplace(type)}.TITULO`)}:
            <span className="vg-filter-smart-placeholder">
              {intl.getHTML("VAGAS.BUSCA.ITEM", { value: totalSelected })}
            </span>
            <Icon iconKey="arrow" aria-hidden />
          </button>
        ) : (
          <h2 className="vg-filter-title">
            {intl.get(`VAGAS.BUSCA.${intlReplace(type)}.TITULO`)}:
            <span className="vg-filter-smart-placeholder">
              {intl.getHTML("VAGAS.BUSCA.ITEM", { value: totalSelected })}
            </span>
          </h2>
        )}
        <div className="vg-menu-filter" id={`${type}-menu`}>
          {!!filters.length && content.length > totalList && (
            <div className="vg-filter-selected">
              <label className="vg-label-title">
                {intl.get("VAGAS.BUSCA.SELECIONADOS")}
                <button onClick={() => uncheckAll(type)}>
                  {intl.get("VAGAS.BUSCA.LIMPAR_FILTRO")}
                </button>
              </label>
              <div className="vg-filter-scroll">
                {map(filters, (item) => (
                  <div className="vg-custom-check" key={`selected-${item}`}>
                    <label className="vg-label">
                      <span className="vg-input-checkbox">
                        <input
                          type="checkbox"
                          defaultChecked="true"
                          value={item}
                          name={`selected-${type}`}
                          onChange={() => uncheck(type, item)}
                        />
                        <span className="vg-input-checkbox-inner" />
                      </span>
                      <DynamicLabel item={item} items={content} />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          <InputFilter
            content={content ? content.length : 0}
            filter={this.filter}
            type={type}
            loading={this.loading}
            ariaLabel={ariaLabel}
          />
          <div className={`vg-filter-scroll ${loading && "vg-filter-loading"}`}>
            <fieldset>
              <legend aria-label={ariaLabel}>
                {map(items, (item) => (
                  <div
                    className={`vg-custom-check ${
                      !filtered.some((filter) => filter.key === item.key) &&
                      filtering &&
                      "hide"
                    }`}
                    key={`${type}-${item.key}`}
                  >
                    <label className="vg-label">
                      <span className="vg-input-checkbox">
                        <Field
                          value={`${item.key}`}
                          name={type}
                          component="input"
                          type="checkbox"
                        />
                        <span className="vg-input-checkbox-inner" />
                      </span>
                      <Label item={item} type={type} />
                    </label>
                  </div>
                ))}
              </legend>
            </fieldset>
            {noResults && (
              <div className="vg-custom-check" aria-live="polite">
                <label className="vg-label">
                  <span className="vg-label-text">Sem resultados</span>
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SearchListTop.defaultProps = {
  totalSelected: 0,
  filters: [],
  content: [],
  groupFilter: false,
  ariaLabel: "",
};

SearchListTop.propTypes = {
  clickOutside: propTypes.func,
  toggleFilter: propTypes.func.isRequired,
  uncheckAll: propTypes.func.isRequired,
  uncheck: propTypes.func.isRequired,
  content: propTypes.array,
  activedFilters: propTypes.array.isRequired,
  filters: propTypes.array,
  type: propTypes.string.isRequired,
  inside: propTypes.bool,
  insideLabel: propTypes.string,
  totalSelected: propTypes.number.isRequired,
  groupFilter: propTypes.bool,
  ariaLabel: propTypes.string,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ toggleFilter, uncheckAll, uncheck }, dispatch)
)(onClickOutside(SearchListTop));
