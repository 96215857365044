import "./VacanciesSearch.scss";

import { ErrorHandler, Filter, Icon } from "../../index";
import React, { Component } from "react";
import {
  loadFacets,
  toggleFilter,
} from "../../../store/modules/VacanciesSearch";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { changeHiddenStatusRequest } from "../../../store/modules/Vacancies";
import intl from "react-intl-universal";
import propTypes from "prop-types";

const mapStateToProps = (state) => ({
  facets: state.VacanciesSearch.facets.data.aggs,
  loaded: state.VacanciesSearch.facets.loaded,
  loading: state.VacanciesSearch.facets.loading,
  error: state.VacanciesSearch.facets.error,
  activedFilters: state.VacanciesSearch.activedFilters,
  initialValues: state.VacanciesSearch.filter,
});

class VacanciesSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 1,
      firstLoaded: false,
      errorHandler: false,
      paginate: false,
      update: false,
    };

    this.loadFilterFacets = this.loadFilterFacets.bind(this);
  }

  componentDidMount() {
    this.loadFilterFacets();
  }

  loadFilterFacets() {
    const { loadFacets } = this.props;
    loadFacets("processos-seletivos/procurar");
  }

  render() {
    const {
      loading,
      facets,
      error,
      loaded,
      activedFilters,
      toggleFilter,
      initialValues,
      featureFlags,
    } = this.props;

    if (error) {
      return (
        <ErrorHandler
          error={error}
          text={intl.get("MENSAGEM_DE_ERRO.FILTRO_BUSCA_NAO_CARREGADO")}
          retryHandler={this.loadFilterFacets}
        />
      );
    }

    if (loading) {
      return (
        <div className="vg-search-wrapper">
          <div className="vg-search-loader">
            <Icon iconKey="searchLoader" aria-hidden/>
          </div>
          <div className="vg-title-loader">
            <Icon iconKey="titleLoader" aria-hidden/>
          </div>
          <div className="vg-vacancy-loader">
            <Icon iconKey="jobLoader" aria-hidden/>
          </div>
          <div className="vg-vacancy-loader">
            <Icon iconKey="jobLoader" aria-hidden/>
          </div>
        </div>
      );
    }

    if (loaded) {
      return (
        <div className="vg-search-wrapper">
          <Filter
            facets={facets}
            activedFilters={activedFilters}
            toggleFilter={toggleFilter}
            initialValues={initialValues}
            featureFlags={featureFlags}
          />
        </div>
      );
    }
  }
}

VacanciesSearch.defaultProps = {
  type: "",
  filters: {},
  filterState: {},
};

VacanciesSearch.propTypes = {
  facets: propTypes.object.isRequired,
  activedFilters: propTypes.array.isRequired,
  loadFacets: propTypes.func.isRequired,
  hideRequest: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  error: propTypes.any,
  toggleFilter: propTypes.func.isRequired,
  isSearching: propTypes.object,
  initialValues: propTypes.object,
  filterState: propTypes.object,
  featureFlags: propTypes.array,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ loadFacets, hideRequest: changeHiddenStatusRequest, toggleFilter }, dispatch),
)(VacanciesSearch);
